import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import websiteApi from "apis/websiteAPI";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import "../App.css";

export const utils = {};

export const checkPathnameHadCondition = (pathname = "") => {
  return (
    pathname.includes("choose-country") || pathname.includes("choose-language")
  );
};
export const getText = (arr, key) => {
  return arr?.[key]?.description ?? "";
};

export const useGetKeyWords = () => {
  const navigate = useNavigate();
  const country = JSON.parse(localStorage.getItem("country") ?? `{}`);
  const language = JSON.parse(localStorage.getItem("language") ?? `{}`);

  const language_id = language?.id;
  const country_id = country?.id;

  const { data, isLoading, ...rest } = useQuery(
    ["content", language_id, country_id],
    () => websiteApi.getAllContent(country_id, language_id),
    {
      select: (val) => _.keyBy(val.data.data, "keyword"),
      enabled: !!language_id && !!country_id,
      onError: () => {
        localStorage.clear();
        navigate("/choose-country", { replace: true });
      },
    }
  );

  return {
    data,
    isLoading,
    ...rest,
  };
};
export const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#376237",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#376237e6",
  },
  borderRadius: "8px",
  fontSize: "18px",
  minHeight: "46px",
}));
export function CheckEmailComp({ text = "" }) {
  const textShow = text.trim();
  let email = text;
  const regexCheckMail = new RegExp(`^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`);
  const regexCheckDotEnd = new RegExp(`\\.$`);

  if (regexCheckDotEnd.test(textShow)) {
    email = text.slice(0, -1);
  }
  if (regexCheckMail.test(email)) {
    return (
      <a
        target={"_blank"}
        rel="noopener noreferrer"
        className="text-des-blue-color"
        href={`mailto: ${email}`}
      >
        {textShow}{" "}
      </a>
    );
  }
  return (
    <div
      style={{ display: "inline" }}
      dangerouslySetInnerHTML={{
        __html: textShow,
      }}
    />
  );
}

export const checkIsMail = (text) => {
  const regexCheckMail = new RegExp(`^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$`);
  if (regexCheckMail.test(text)) {
    return true;
  }
  return false;
};
