const useCheckParams = () => {
  const country = JSON.parse(localStorage.getItem("country"));
  const language = JSON.parse(localStorage.getItem("language"));
  const country_id = country?.id;
  const language_id = language?.id;

  return {
    language_id,
    country_id,
    country: country,
    language: language,
  };
};

export default useCheckParams;
