import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { KEY_LANGUAGE } from "constants/language";
import { Link } from "react-router-dom";
import { ColorButton, getText, useGetKeyWords } from "utils";

export default function PageNotFound() {
  const { data } = useGetKeyWords();
  return (
    <Box
      textAlign={"center"}
      pt={4}
      sx={{ backgroundColor: "#42A046", color: "white" }}
    >
      <Typography variant="h3" fontWeight={"bold"} fontSize={80}>
        {getText(data, KEY_LANGUAGE.microsite_page_not_found_1)}
      </Typography>
      <Typography fontWeight={"bold"} variant="h3" fontSize={60}>
        {getText(data, KEY_LANGUAGE.microsite_page_not_found_2)}
      </Typography>
      <Link to={"/"} replace>
        <ColorButton
          type="button"
          variant="contained"
          className="button-primary"
        >
          {getText(data, KEY_LANGUAGE.microsite_page_not_found_3)}
        </ColorButton>
      </Link>
      <Box
        className="banner-background homepage"
        sx={{ background: "#42A046" }}
      >
        <div
          style={{
            zIndex: 1,
            position: "inherit",
            margin: "0 auto",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "500px",
              objectFit: "contain",
            }}
            src="images/banner_image.png"
            alt="Banner"
          />
        </div>
      </Box>
    </Box>
  );
}
