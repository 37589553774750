import { Add, Remove } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Container,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import websiteApi from "apis/websiteAPI";
import { getContentReply } from "constants/contactUsReply";
import { KEY_LANGUAGE } from "constants/language";
import ContactUs from "containers/ContactUs/ContactUs";
import ModalContactUs from "containers/ContactUs/components/ModalContactUs";
import dayjs from "dayjs";
import HeaderNav from "layouts/HeaderNav";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Loading } from "shared_components";
import { getText, useGetKeyWords } from "utils";
import Footer from "layouts/Footer";

export default function ContactUsLandingPage() {
  const [open, setOpen] = useState(false);
  const { data: objText } = useGetKeyWords();
  const [params] = useSearchParams();
  const [imageUrl, setImageUrl] = useState("");

  const idContact = useMemo(() => params.get("id"), [params]);

  const handleClose = () => {
    setImageUrl("");
  };

  const handleImage = (value) => {
    setImageUrl(value);
  };

  useEffect(() => {
    const token = params.get("token");
    if (token) {
      sessionStorage.setItem("TOKEN", token);
    }
  }, [params]);

  const {
    data: info,
    isLoading,
    isError,
  } = useQuery(["info-contact", idContact], () =>
    websiteApi.getInfoContactUsReply(idContact)
  );

  const handleClick = (i) => {
    if (open === i) {
      setOpen();
      return;
    }
    setOpen(i);
  };

  const headObj = {
    action: getText(objText, KEY_LANGUAGE.landing_page_action),
    updatedDate: getText(objText, KEY_LANGUAGE.landing_page_updated_date),
  };

  const renderList = (_data) => {
    return _data.map((e, i) => {
      const isOpen = open === i;
      const isHead = i === 0;
      return (
        <Container maxWidth="md" pb={2} key={i} className="faq-wrapper">
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            disablePadding
          >
            <ListItemButton
              disabled={isHead}
              style={{
                background: isHead ? "#376237" : isOpen ? "#E5E5E5" : "#FFFFFF",
                color: isHead ? "#FFFFFF" : "#000000",
                fontWeight: "bold",
                opacity: 1,
              }}
              onClick={() => handleClick(i)}
            >
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <ListItemText
                    primary={
                      e.action ?? getContentReply(objText, e?.type) ?? "-"
                    }
                    primaryTypographyProps={{
                      fontWeight: isHead && "bold",
                      fontSize: { xs: "14px", sm: "16px" },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ListItemText
                    primary={
                      isHead
                        ? e.updatedDate
                        : e.created_at
                        ? dayjs(e.created_at).format("DD/MM/YYYY HH:mm:ss")
                        : "-"
                    }
                    primaryTypographyProps={{
                      fontWeight: isHead && "bold",
                      fontSize: { xs: "14px", sm: "16px" },
                    }}
                  />
                </Grid>
              </Grid>

              {isHead ? null : isOpen ? (
                <Remove style={{ color: "#266432" }} />
              ) : (
                <Add style={{ color: "#266432" }} />
              )}
            </ListItemButton>
            <Collapse
              style={{ paddingBottom: 10 }}
              in={isOpen}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                sx={{
                  px: {
                    xs: "10px",
                    sm: "10px",
                    md: "40px",
                  },
                }}
              >
                <ListItemText
                  primary={
                    <>
                      <div style={{ fontWeight: "bold", paddingBottom: "4px" }}>
                        {e?.subject}
                      </div>
                      <div
                        style={{ paddingBottom: "4px", whiteSpace: "pre-line" }}
                        dangerouslySetInnerHTML={{ __html: e?.content }}
                      />
                    </>
                  }
                  primaryTypographyProps={{
                    fontSize: { xs: "13px", sm: "16px" },
                  }}
                />
                {e?.images?.length !== 0 &&
                  e?.images?.map((e) => (
                    <Box
                      key={e}
                      sx={{
                        width: 100,
                        height: 100,
                        display: "inline",
                      }}
                    >
                      <Box className="img-block img-block-show bg-gray">
                        <img
                          className="img-fluid2"
                          src={e}
                          alt={"img-landingpage"}
                          onClick={() => handleImage(e)}
                        />
                      </Box>
                    </Box>
                  ))}
              </List>
            </Collapse>
          </List>
          <Divider variant="fullWidth" />
        </Container>
      );
    });
  };
  return (
    <>
      <HeaderNav
        showMenu={false}
        showSelectLanguage={false}
        haveBackHomePage={false}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isError ? (
            <Box sx={{ height: "calc(100vh - 320px)" }}>
              <img
                style={{ maxWidth: "500px", width: "80%", margin: "0 auto" }}
                src={"../images/not_found.png"}
                alt="Not found"
              />
            </Box>
          ) : (
            <>
              <ContactUs
                landingPage={true}
                dataForm={info?.data?.data}
                id={idContact}
              />
              {renderList([headObj, ...info?.data?.data?.contact_us_replies])}
              <ModalContactUs
                open={imageUrl?.length > 0}
                handleClose={handleClose}
                objText={objText}
                imageUrl={imageUrl}
              />
            </>
          )}
        </>
      )}
      <Footer />
    </>
  );
}
