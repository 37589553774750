import { CloseOutlined } from "@mui/icons-material";
import { Box, Modal, Typography } from "@mui/material";
import { getTypeModal } from "constants/contactUsReply";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxWidth: 500,
  borderRadius: 4,
};

const ModalContactUs = ({
  open,
  handleClose = () => null,
  objText,
  imageUrl,
}) => {
  const getTextContent = getTypeModal(objText, open);
  return (
    <Modal
      open={!!open}
      onClose={handleClose}
      aria-labelledby="title"
      aria-describedby="description"
    >
      {imageUrl ? (
        <Box
          style={{ position: "relative", height: "100vh", paddingTop: "10vh" }}
          onClick={handleClose}
        >
          <img
            src={imageUrl}
            alt="img-preview"
            style={{
              objectFit: "scale-down",
              maxHeight: "80%",
              maxWidth: "80%",
              margin: "auto",
            }}
            // onClick={(e) => e.stopPropagation()}
          />
          <Box
            textAlign={"end"}
            sx={{ position: "absolute", top: "10px", right: "20px" }}
          >
            <CloseOutlined
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ ...style }}>
          <Box
            textAlign={"end"}
            sx={{ position: "absolute", top: "10px", right: "20px" }}
          >
            <CloseOutlined
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography
            fontWeight={"bold"}
            className="text-title-green"
            variant="h4"
            fontSize={"30px"}
            textAlign={"center"}
          >
            {getTextContent?.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} fontSize={18}>
            {getTextContent?.content}
          </Typography>
        </Box>
      )}
    </Modal>
  );
};

export default ModalContactUs;
