import styled from "@emotion/styled";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import websiteApi from "apis/websiteAPI";
import { typeModal } from "constants/contactUsReply";
import { KEY_LANGUAGE } from "constants/language";
import { useFormik } from "formik";
import useLocalStorage from "hooks/useLocalStorage";
import DOMPurify from "isomorphic-dompurify";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UploadImage } from "shared_components";
import HeaderPage from "shared_components/HeaderPage";
import { getText, useGetKeyWords } from "utils";
import * as yup from "yup";
import ModalContactUs from "./components/ModalContactUs";

const CustomTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#41A047",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#41A047",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    "&:hover fieldset": {
      borderColor: "#41A047",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#41A047",
    },
  },
});

const CustomSelect = styled(Select)({
  borderRadius: "10px",
  "& label.Mui-focused": {
    color: "#41A047 !important",
  },
  "&.MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#41A047",
      color: "#41A047",
    },
    "&:hover fieldset": {
      "&.MuiOutlinedInput-notchedOutline": {
        borderColor: "#41A047",
      },
    },
  },
});

const CustomRadio = styled(Radio)({
  color: "#41A047",
  "&.Mui-checked": {
    color: "#41A047",
  },
});

export default function ContactUs({ landingPage = false, dataForm, id }) {
  const country = JSON.parse(localStorage.getItem("country") ?? `{}`);
  const [language] = useLocalStorage("language");
  const [open, setOpen] = useState();
  const [disabled, setDisabled] = useState(false);
  const { data: objText } = useGetKeyWords();
  const [fileList, setFileList] = useState([]);
  const queryClient = useQueryClient();

  const validationSchema = yup.object({
    lastname: yup
      .string()
      .trim()
      .required(getText(objText, KEY_LANGUAGE.required_info)),
    firstname: yup
      .string()
      .trim()
      .required(getText(objText, KEY_LANGUAGE.required_info)),
    city: yup
      .string()
      .trim()
      .required(getText(objText, KEY_LANGUAGE.required_info)),
    content: yup
      .string()
      .trim()
      .required(getText(objText, KEY_LANGUAGE.required_info)),
    country_id: yup
      .string()
      .trim()
      .required(getText(objText, KEY_LANGUAGE.required_info)),
    is_herbalife_member: yup.boolean(),
    agree: yup
      .bool()
      .oneOf([true], getText(objText, KEY_LANGUAGE.check_accept_privacy)),
    nature_of_enquiry: yup
      .number()
      .required(getText(objText, KEY_LANGUAGE.required_info)),
    email: yup
      .string()
      .trim()
      .required(getText(objText, KEY_LANGUAGE.required_info))
      .matches(
        "^\\S+@\\S+\\.\\S+$",
        getText(objText, KEY_LANGUAGE.enter_invalid_email)
      ),
  });
  const formik = useFormik({
    initialValues: {
      email: dataForm?.email ?? "",
      name: dataForm?.firstname + " " + dataForm?.lastname ?? "",
      firstname: dataForm?.firstname ?? "",
      lastname: dataForm?.lastname ?? "",
      city: dataForm?.city ?? "",
      content: "",
      country_id: country?.id,
      language_id: language?.id,
      agree: landingPage,
      nature_of_enquiry: dataForm?.nature_of_enquiry ?? "",
      is_herbalife_member: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (landingPage) {
        const resultAfterDompurify = {
          content: DOMPurify.sanitize(values.content),
        };
        handleSubmitReply(resultAfterDompurify);
      } else {
        const resultAfterDompurify = {
          ...values,
          is_herbalife_member: Boolean(values.is_herbalife_member),
          city: DOMPurify.sanitize(values.city),
          content: DOMPurify.sanitize(values.content),
          firstname: DOMPurify.sanitize(values.firstname),
          lastname: DOMPurify.sanitize(values.lastname),
        };
        postContact.mutate({
          payload: resultAfterDompurify,
        });
        setDisabled(true);
      }
    },
  });

  useEffect(() => {
    formik.validateForm();

    //eslint-disable-next-line
  }, []);

  const postContact = useMutation(
    ({ payload }) => websiteApi.postContactUs(payload),
    {
      onSuccess: () => {
        setOpen(typeModal.success.key);
        formik.resetForm();
      },
      onSettled: () => {
        setDisabled(false);
      },
    }
  );
  const replyContact = useMutation(
    ({ id, payload }) => websiteApi.postContactUsReply(id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["info-contact", id]);
        setOpen(typeModal.success.key);
        formik.resetForm();
        setFileList([]);
      },
      onError: ({ response }) => {
        if (response?.data?.status_code === 409) {
          setOpen(typeModal.notice.key);
        }
      },
      onSettled: () => {
        setDisabled(false);
      },
    }
  );

  const handleSubmitReply = (values) => {
    const token = sessionStorage.getItem("TOKEN");
    const result = {
      ...values,
      media_files: fileList.map((e) => e.id),
      token: token,
    };
    replyContact.mutate({ id: id, payload: result });
  };

  const { data } = useQuery(["country"], () => websiteApi.getAllCountry(), {
    select: (val) => val.data,
  });
  const handleChange = (e, name, form) => {
    form?.setFieldTouched(name);
    return form?.handleChange(e);
  };
  const RequiredLabel = ({ title }) => {
    return (
      <Typography sx={{ minWidth: "156px" }} fontWeight="bold">
        {title}
        <span className="required-label">*</span>
      </Typography>
    );
  };
  const handleClose = () => {
    setOpen();
  };
  return (
    <div className="register">
      <HeaderPage
        text={getText(objText, KEY_LANGUAGE.microsite_contact_us_1)}
      />
      <Container sx={{ paddingTop: "60px" }} maxWidth="md">
        <form onSubmit={formik.handleSubmit}>
          {landingPage ? (
            <>
              <Grid container spacing={{ xs: 1, sm: 3 }} marginBottom={1}>
                <Grid item xs={12} sm={6}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 2 }}
                    justifyContent="start"
                    alignItems="baseline"
                  >
                    <RequiredLabel
                      title={getText(objText, KEY_LANGUAGE.landing_page_name)}
                    />
                    <CustomTextField
                      size="small"
                      fullWidth
                      id="name"
                      name="name"
                      label={getText(
                        objText,
                        KEY_LANGUAGE.microsite_contact_us_3
                      )}
                      value={formik.values.name}
                      disabled={true}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack
                    mb={2}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 2 }}
                    justifyContent="start"
                    alignItems="baseline"
                  >
                    <RequiredLabel
                      title={getText(
                        objText,
                        KEY_LANGUAGE.microsite_contact_us_9
                      )}
                    />
                    <CustomTextField
                      fullWidth
                      size="small"
                      id="email"
                      name="email"
                      label={getText(
                        objText,
                        KEY_LANGUAGE.microsite_contact_us_10
                      )}
                      value={formik.values.email}
                      disabled={true}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={{ xs: 1, sm: 3 }} marginBottom={1}>
                <Grid item xs={12} sm={6}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 2 }}
                    justifyContent="start"
                    alignItems="baseline"
                  >
                    <RequiredLabel
                      title={getText(
                        objText,
                        KEY_LANGUAGE.microsite_contact_us_2
                      )}
                    />
                    <CustomTextField
                      size="small"
                      fullWidth
                      id="firstname"
                      name="firstname"
                      onBlur={formik.handleBlur}
                      label={getText(
                        objText,
                        KEY_LANGUAGE.microsite_contact_us_3
                      )}
                      value={formik.values.firstname}
                      onChange={(e) => handleChange(e, "firstname", formik)}
                      error={
                        formik.touched.firstname &&
                        Boolean(formik.errors.firstname)
                      }
                      helperText={
                        formik.touched.firstname && formik.errors.firstname
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 2 }}
                    justifyContent="start"
                    alignItems="baseline"
                  >
                    <RequiredLabel
                      title={getText(
                        objText,
                        KEY_LANGUAGE.microsite_contact_us_4
                      )}
                    />
                    <CustomTextField
                      fullWidth
                      size="small"
                      id="lastname"
                      name="lastname"
                      onBlur={formik.handleBlur}
                      label={getText(
                        objText,
                        KEY_LANGUAGE.microsite_contact_us_5
                      )}
                      value={formik.values.lastname}
                      onChange={(e) => handleChange(e, "lastname", formik)}
                      error={
                        formik.touched.lastname &&
                        Boolean(formik.errors.lastname)
                      }
                      helperText={
                        formik.touched.lastname && formik.errors.lastname
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
              <FormControl sx={{ mb: 2 }}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2 }}
                  justifyContent="start"
                  alignItems="center"
                >
                  <RequiredLabel
                    title={getText(
                      objText,
                      KEY_LANGUAGE.microsite_contact_us_6
                    )}
                  />
                  <RadioGroup
                    aria-labelledby="cus-group"
                    name="is_herbalife_member"
                    row
                    value={formik.values.is_herbalife_member}
                    onChange={(e) =>
                      handleChange(e, "is_herbalife_member", formik)
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<CustomRadio />}
                      label={getText(
                        objText,
                        KEY_LANGUAGE.microsite_contact_us_7
                      )}
                    />
                    <FormControlLabel
                      value={false}
                      control={<CustomRadio />}
                      label={getText(
                        objText,
                        KEY_LANGUAGE.microsite_contact_us_8
                      )}
                    />
                  </RadioGroup>
                </Stack>
              </FormControl>
              <Stack
                mb={2}
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2 }}
                justifyContent="start"
                alignItems="baseline"
              >
                <RequiredLabel
                  title={getText(objText, KEY_LANGUAGE.microsite_contact_us_9)}
                />
                <CustomTextField
                  fullWidth
                  size="small"
                  id="email"
                  name="email"
                  onBlur={formik.handleBlur}
                  label={getText(objText, KEY_LANGUAGE.microsite_contact_us_10)}
                  value={formik.values.email}
                  onChange={(e) => handleChange(e, "email", formik)}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Stack>
            </>
          )}

          <Grid container spacing={{ xs: 1, sm: 3 }} mb={2}>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2 }}
                justifyContent="start"
                alignItems="baseline"
              >
                <RequiredLabel
                  title={getText(objText, KEY_LANGUAGE.microsite_contact_us_11)}
                />
                <CustomTextField
                  fullWidth
                  id="city"
                  size="small"
                  name="city"
                  onBlur={formik.handleBlur}
                  label={getText(objText, KEY_LANGUAGE.microsite_contact_us_12)}
                  value={formik.values.city}
                  onChange={(e) => handleChange(e, "city", formik)}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                  disabled={landingPage}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2 }}
                justifyContent="start"
                alignItems="baseline"
              >
                <RequiredLabel
                  title={getText(objText, KEY_LANGUAGE.microsite_contact_us_13)}
                />
                <FormControl
                  fullWidth
                  sx={{
                    "& label.Mui-focused": {
                      color: "#41A047",
                    },
                  }}
                >
                  <InputLabel
                    size="small"
                    id="select-label-country"
                    sx={{ width: "fit-content" }}
                  >
                    {getText(objText, KEY_LANGUAGE.microsite_contact_us_13)}
                  </InputLabel>
                  <CustomSelect
                    size="small"
                    labelId="select-label-country"
                    id="select-country"
                    name="country_id"
                    label={getText(
                      objText,
                      KEY_LANGUAGE.microsite_contact_us_13
                    )}
                    disabled
                    onBlur={formik.handleBlur}
                    value={formik.values.country_id}
                    onChange={(e) => handleChange(e, "country_id", formik)}
                    error={
                      formik.touched.country_id &&
                      Boolean(formik.errors.country_id)
                    }
                  >
                    {data?.data?.map((e) => {
                      return (
                        <MenuItem key={e.id} value={e.id}>
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  </CustomSelect>
                  <FormHelperText
                    error={
                      formik.touched.country_id &&
                      Boolean(formik.errors.country_id)
                    }
                  >
                    {formik.touched.country_id && formik.errors.country_id}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
          <Stack
            marginBottom={2}
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2 }}
            justifyContent="start"
            alignItems="baseline"
          >
            <RequiredLabel
              title={getText(objText, KEY_LANGUAGE.microsite_contact_us_15)}
            />
            <FormControl
              fullWidth
              sx={{
                "& label.Mui-focused": {
                  color: "#41A047",
                },
              }}
            >
              <InputLabel
                size="small"
                id="select-label-nature-of-enquiry"
                className={
                  formik.touched.nature_of_enquiry &&
                  Boolean(formik.errors.nature_of_enquiry)
                    ? "Mui-error"
                    : ""
                }
              >
                {getText(objText, KEY_LANGUAGE.microsite_contact_us_15)}
              </InputLabel>
              <CustomSelect
                size="small"
                labelId="select-label-nature-of-enquiry"
                id="select-nature-of-enquiry"
                onBlur={formik.handleBlur}
                label={getText(objText, KEY_LANGUAGE.microsite_contact_us_15)}
                name="nature_of_enquiry"
                value={formik.values.nature_of_enquiry}
                onChange={(e) => handleChange(e, "nature_of_enquiry", formik)}
                error={
                  formik.touched.nature_of_enquiry &&
                  Boolean(formik.errors.nature_of_enquiry)
                }
                disabled={landingPage}
              >
                <MenuItem value={1}>
                  {getText(objText, KEY_LANGUAGE.microsite_contact_us_18)}{" "}
                </MenuItem>
                <MenuItem value={2}>
                  {getText(objText, KEY_LANGUAGE.microsite_contact_us_19)}{" "}
                </MenuItem>
                <MenuItem value={3}>
                  {getText(objText, KEY_LANGUAGE.microsite_contact_us_20)}{" "}
                </MenuItem>
                <MenuItem value={4}>
                  {getText(objText, KEY_LANGUAGE.microsite_contact_us_21)}{" "}
                </MenuItem>
                <MenuItem value={5}>
                  {getText(objText, KEY_LANGUAGE.microsite_contact_us_22)}{" "}
                </MenuItem>
                <MenuItem value={6}>
                  {getText(objText, KEY_LANGUAGE.microsite_contact_us_23)}{" "}
                </MenuItem>
                <MenuItem value={7}>
                  {getText(objText, KEY_LANGUAGE.microsite_contact_us_24)}{" "}
                </MenuItem>
              </CustomSelect>
              <FormHelperText
                error={
                  formik.touched.nature_of_enquiry &&
                  Boolean(formik.errors.nature_of_enquiry)
                }
              >
                {formik.touched.nature_of_enquiry &&
                  formik.errors.nature_of_enquiry}
              </FormHelperText>
            </FormControl>
          </Stack>
          <Stack
            marginBottom={2}
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2 }}
            justifyContent="start"
            alignItems="baseline"
          >
            <RequiredLabel
              title={getText(objText, KEY_LANGUAGE.microsite_contact_us_16)}
            />
            <FormControl fullWidth>
              <CustomTextField
                fullWidth
                size="small"
                multiline
                rows={4}
                id="content"
                name="content"
                label={getText(objText, KEY_LANGUAGE.microsite_contact_us_17)}
                onBlur={formik.handleBlur}
                value={formik.values.content}
                onChange={(e) => handleChange(e, "content", formik)}
                error={formik.touched.content && Boolean(formik.errors.content)}
                helperText={formik.touched.content && formik.errors.content}
              />
            </FormControl>
          </Stack>
          {landingPage ? (
            <UploadImage
              objText={objText}
              setFileList={setFileList}
              fileList={fileList}
              handleImgValid={(val) => setOpen(val)}
            />
          ) : (
            <FormControl
              fullWidth
              sx={{
                "& label.Mui-focused": {
                  color: "#41A047",
                },
              }}
            >
              <Stack
                direction="column"
                justifyContent="start"
                mt={2}
                ml={2}
                pl={{ sm: "156px" }}
                width="fit-content"
              >
                <FormControlLabel
                  onChange={(e) => handleChange(e, "agree", formik)}
                  name="agree"
                  checked={formik.values.agree}
                  control={
                    <Checkbox
                      sx={{
                        marginRight: "10px",
                        color: "green",
                        "&.Mui-checked": {
                          color: "#41A047",
                        },
                      }}
                    />
                  }
                  fullWidth
                  label={
                    <span>
                      {getText(objText, KEY_LANGUAGE.microsite_contact_us_24_1)}{" "}
                      {
                        <Link
                          target={"_blank"}
                          rel="noopener noreferrer"
                          to={"/privacy-policy"}
                          style={{ color: "#1c75bc" }}
                        >
                          <u>
                            {getText(
                              objText,
                              KEY_LANGUAGE.microsite_contact_us_24_2
                            )}
                          </u>
                        </Link>
                      }
                    </span>
                  }
                />
                <FormHelperText
                  sx={{ marginLeft: 0 }}
                  error={formik.touched.agree && Boolean(formik.errors.agree)}
                >
                  {formik.touched.agree && formik.errors.agree}
                </FormHelperText>
              </Stack>
            </FormControl>
          )}
          <Box textAlign={"center"} my={4}>
            <Button
              sx={{
                minWidth: "130px",
                height: "50px",
                fontSize: "20px",
                fontWeight: "bold",
                backgroundColor: "#376237",
                "&:hover": {
                  backgroundColor: "#376237e6",
                },
              }}
              className="button-primary"
              size="medium"
              variant="contained"
              type="submit"
              disabled={disabled || Object.keys(formik.errors).length > 0}
            >
              {getText(objText, KEY_LANGUAGE.microsite_contact_us_25)}
            </Button>
          </Box>
        </form>
      </Container>
      <ModalContactUs open={open} handleClose={handleClose} objText={objText} />
    </div>
  );
}
