import styled from "@emotion/styled";
import { CloseOutlined, LogoutOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Button, Container, Menu, Stack } from "@mui/material";
import { KEY_LANGUAGE } from "constants/language";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getText } from "utils";
import SelectLanguage from "./components/SelectLanguage";
import TabsHeaderMobiles from "./components/TabsHeaderMobile";

const ButtonCustom = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  width: "50px",
  minWidth: "auto",
  padding: { xs: "0", sm: "6px 12px" },
  color: "#000",
  "&:hover": {
    boxShadow: "none",
    background: "transparent",
  },
  "&:active": {
    boxShadow: "none",
  },
});

const HeaderNav = ({ data, showMenu = true, showSelectLanguage = true, haveBackHomePage= true }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dataTabs = [
    {
      key: 1,
      label: getText(data, KEY_LANGUAGE.microsite_homepage_3_1_1),
      url: "/",
    },
    {
      key: 2,
      label: getText(data, KEY_LANGUAGE.microsite_homepage_3_2_1),
      url: "/how-to-register",
    },
    {
      key: 3,
      label: getText(data, KEY_LANGUAGE.microsite_homepage_3_3),
      url: "/how-to-submit",
    },
    {
      key: 4,
      label: getText(data, KEY_LANGUAGE.microsite_homepage_3_4),
      url: "/FAQs",
    },
    {
      key: 5,
      label: getText(data, KEY_LANGUAGE.microsite_homepage_3_5),
      url: "/terms-and-conditions",
    },
    {
      key: 6,
      label: getText(data, KEY_LANGUAGE.microsite_homepage_3_6),
      url: "/contact-us",
    },
  ];
  const handleClearLocal = () => {
    localStorage.clear();
    navigate("/choose-country");
  };
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        maxWidth: "100vw",
        overflow: "hidden",
        background: "#fff",
        boxShadow: "0px 0px 4px #bdbdbd",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{ paddingLeft: { xs: !showMenu ? "24px" : 0, sm: "24px" } }}
      >
        <Stack
          sx={{
            height: { xs: "60px", sm: "80px" },
            display: "flex",
            alignItems: "center",
          }}
          direction="row"
          className={{ xs: "", sm: "container" }}
          alignItems="center"
          justifyContent="space-between"
          spacing={{ xs: 4, md: 12 }}
        >
          <Stack alignItems="center" direction="row" spacing={1}>
            {showMenu && (
              <Box>
                <ButtonCustom
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  size="small"
                >
                  {open ? <CloseOutlined /> : <MenuIcon />}
                </ButtonCustom>
              </Box>
            )}

            <Box sx={{ display: "flex", minWidth: "100px", maxWidth: "140px" }}>
              <Box
                sx={{ width: "fit-content", height: "50px", textAlign: "end" }}
              >
                {haveBackHomePage ? (
                  <Link to="/">
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      src="images/logo_hbl.png"
                      alt="Logo HBL"
                    />
                  </Link>
                ) : (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src="images/logo_hbl.png"
                    alt="Logo HBL"
                  />
                )}
              </Box>
            </Box>
          </Stack>
          {showSelectLanguage && (
            <Stack
              direction="row"
              spacing={{ xs: 0, sm: 2 }}
              ml={"4px !important"}
            >
              <Box
                sx={{ flexGrow: 1, p: 0 }}
                direction="row"
                justifyContent="space-between"
                maxWidth={"100%"}
              >
                <SelectLanguage />
              </Box>
              <Button
                onClick={() => handleClearLocal()}
                sx={{
                  color: "#376237",
                  fontWeight: "bold",
                  padding: 0,
                  minWidth: "auto",
                }}
                endIcon={<LogoutOutlined />}
              />
            </Stack>
          )}
          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              aria-labelledby="demo-positioned-button"
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{ width: "100%" }}
              placement="bottom-start"
            >
              <TabsHeaderMobiles handleClose={handleClose} data={dataTabs} />
            </Menu>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default HeaderNav;
