import { Box, Container, Grid, Stack } from "@mui/material";
import { KEY_LANGUAGE } from "constants/language";
import { Link } from "react-router-dom";
import { getText, useGetKeyWords } from "utils";

const Footer = () => {
  const { data } = useGetKeyWords();

  return (
    <>
      <Box sx={{ background: "#E4E4E4" }}>
        <Container
          sx={{
            minHeight: "100px",
            mt: "20px",
            pt: "10px",
          }}
        >
          <div style={{ maxWidth: "180px" }}>
            <img src="images/logo_hbl.png" alt="Logo HBL"></img>
          </div>
          <Grid
            pt={1}
            paddingBottom={2}
            spacing={4}
            container
            columns={{ xs: 4, sm: 12 }}
            alignItems="center"
          >
            <Grid item sm={8} xs={12}>
              <Box mt={3} fontWeight={400}>
                <Box>
                  <div>{getText(data, KEY_LANGUAGE.microsite_footer_3)}</div>
                  <div>{getText(data, KEY_LANGUAGE.microsite_footer_4)}</div>
                  <div>{getText(data, KEY_LANGUAGE.microsite_footer_5)}</div>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box display={"flex"} justifyContent={"end"}>
                <div>
                  <Box fontWeight={"bold"} className="text-title-green">
                    {" "}
                    {getText(data, KEY_LANGUAGE.microsite_footer_7)}
                  </Box>
                  <Stack direction={"row"} justifyContent="center" spacing={2}>
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                    >
                      <a
                        target={"_blank"}
                        rel="noopener noreferrer"
                        href={getText(data, KEY_LANGUAGE.microsite_footer_8)}
                      >
                        <img
                          src="images/instargram_icon.png"
                          alt="Logo Instargram"
                        />
                      </a>
                    </Box>
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                    >
                      <a
                        target={"_blank"}
                        rel="noopener noreferrer"
                        href={getText(data, KEY_LANGUAGE.microsite_footer_9)}
                      >
                        <img
                          src="images/facebook_icon.png"
                          alt="Logo Facebook"
                        />
                      </a>
                    </Box>
                    {getText(data, KEY_LANGUAGE.microsite_footer_10).trim()
                      ?.length !== 0 && (
                      <Box
                        sx={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                      >
                        <a
                          target={"_blank"}
                          rel="noopener noreferrer"
                          href={getText(data, KEY_LANGUAGE.microsite_footer_10)}
                        >
                          <img
                            src="images/youtube_icon.svg"
                            alt="Logo Youtube"
                          />
                        </a>
                      </Box>
                    )}
                  </Stack>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Box pb={2}>
            <Link
              target={"_blank"}
              rel="noopener noreferrer"
              style={{ color: "#1c75bc" }}
              to={"/privacy-policy"}
            >
              <u>{getText(data, KEY_LANGUAGE.microsite_footer_6)}</u>
            </Link>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
