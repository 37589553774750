import {  Container, Stack } from "@mui/material";

export default function HeaderPage({ text }) {
  return (
    <div className="header-page mb-5">
      <Container maxWidth="lg">
        <Stack className="wrapper-header" direction={'row'} justifyContent="space-between" sx={{ height: "160px", paddingLeft:'20px' }}>
        <div className="header-page-title">{text}</div>
          <div
          className="header-page-logo"
          >
            <img
              style={{ width: "100%" }}
              src="images/logo_hr.png"
              alt="Banner"
            />
          </div>
        </Stack>
      </Container>
    </div>
  );
}
