export const LANGUAGE = {
  condition_btn_back: {
    EN: "Back",
  },
  condition_country_title: {
    EN: "Select Your Country",
  },
  condition_country_des: {
    EN: "Select the country based on your residence and participation.",
  },
  condition_country_button: {
    EN: "Confirm",
  },
  condition_language_title: {
    EN: "Select Your Language",
  },
  condition_language_des: {
    EN: "Select your preferred language.",
  },
  condition_language_button: {
    EN: "Confirm",
  },
};

export const KEY_LANGUAGE = {
  microsite_homepage_1: "microsite_homepage_1",
  microsite_homepage_2: "microsite_homepage2",
  microsite_homepage_3: "microsite_homepage_3",
  microsite_homepage_3_1: "microsite_homepage_3_1",
  microsite_homepage_3_1_1: "microsite_homepage_3_1_1",
  microsite_homepage_3_2: "microsite_homepage_3_2",
  microsite_homepage_3_2_1: "microsite_homepage_3_2_1",
  microsite_homepage_3_3: "microsite_homepage_3_3",
  microsite_homepage_3_4: "microsite_homepage_3_4",
  microsite_homepage_3_5: "microsite_homepage_3_5",
  microsite_homepage_3_6: "microsite_homepage_3_6",
  microsite_homepage_4: "microsite_homepage_4",
  microsite_homepage_5: "microsite_homepage_5",
  microsite_homepage_6: "microsite_homepage_6",
  microsite_homepage_7: "microsite_homepage_7",
  microsite_homepage_8: "microsite_homepage_8",
  microsite_homepage_9: "microsite_homepage_9",
  microsite_homepage_10: "microsite_homepage_10",
  microsite_homepage_11: "microsite_homepage_11",
  microsite_homepage_12: "microsite_homepage_12",
  microsite_homepage_13: "microsite_homepage_13",
  microsite_homepage_14: "microsite_homepage_14",
  microsite_homepage_14_1: "microsite_homepage_14_1",
  microsite_homepage_14_2: "microsite_homepage_14_2",
  microsite_homepage_14_3: "microsite_homepage_14_3",
  microsite_homepage_14_4: "microsite_homepage_14_4",
  microsite_homepage_15: "microsite_homepage_15",
  microsite_homepage_15_1: "microsite_homepage_15_1",
  microsite_homepage_15_1_1: "microsite_homepage_15_1_1",
  microsite_homepage_15_2_1_1: "microsite_homepage_15_2_1_1",
  microsite_homepage_15_2: "microsite_homepage_15_2",
  microsite_homepage_15_2_1: "microsite_homepage_15_2_1",
  microsite_homepage_15_3: "microsite_homepage_15_3",
  microsite_homepage_15_4: "microsite_homepage_15_4",
  microsite_homepage_16: "microsite_homepage_16",
  microsite_homepage_16_1: "microsite_homepage_16_1",
  microsite_homepage_16_1_1: "microsite_homepage_16_1_1",
  microsite_homepage_16_2: "microsite_homepage_16_2",
  microsite_homepage_16_3: "microsite_homepage_16_3",
  microsite_homepage_16_4: "microsite_homepage_16_4",
  microsite_homepage_17: "microsite_homepage_17",
  microsite_homepage_17_1: "microsite_homepage_17_1",
  microsite_homepage_18: "microsite_homepage_18",
  microsite_homepage_18_1: "microsite_homepage_18_1",
  microsite_homepage_18_2: "microsite_homepage_18_2",
  microsite_homepage_18_3: "microsite_homepage_18_3",
  microsite_homepage_19: "microsite_homepage_19",
  microsite_homepage_20: "microsite_homepage_20",
  microsite_homepage_21: "microsite_homepage_21",
  microsite_homepage_21_1: "microsite_homepage_21_1",
  microsite_homepage_21_1_1: "microsite_homepage_21_1_1",
  microsite_homepage_21_2: "microsite_homepage_21_2",
  microsite_homepage_22: "microsite_homepage_22",
  microsite_homepage_22_1: "microsite_homepage_22_1",
  microsite_homepage_22_2: "microsite_homepage_22_2",
  microsite_homepage_22_3: "microsite_homepage_22_3",
  microsite_homepage_22_4: "microsite_homepage_22_4",
  microsite_homepage_23: "microsite_homepage_23",
  microsite_homepage_23_1: "microsite_homepage_23_1",
  microsite_homepage_23_2: "microsite_homepage_23_2",
  microsite_homepage_23_3: "microsite_homepage_23_3",
  microsite_homepage_23_4: "microsite_homepage_23_4",
  microsite_homepage_23_5: "microsite_homepage_23_5",
  microsite_homepage_24: "microsite_homepage_24",
  microsite_homepage_25: "microsite_homepage_25",
  microsite_homepage_25_1: "microsite_homepage_25_1",
  microsite_homepage_26: "microsite_homepage_26",
  microsite_homepage_27: "microsite_homepage_27",
  microsite_homepage_27_1: "microsite_homepage_27_1",
  microsite_homepage_27_2: "microsite_homepage_27_2",
  microsite_homepage_28: "microsite_homepage_28",
  microsite_homepage_29: "microsite_homepage_29",
  microsite_homepage_29_1: "microsite_homepage_29_1",
  microsite_homepage_30: "microsite_homepage_30",
  microsite_homepage_31: "microsite_homepage_31",
  microsite_homepage_31_1: "microsite_homepage_31_1",
  microsite_homepage_31_2: "microsite_homepage_31_2",
  microsite_homepage_32: "microsite_homepage_32",
  microsite_homepage_33: "microsite_homepage_33",
  microsite_homepage_34: "microsite_homepage_34",
  microsite_homepage_34_1: "microsite_homepage_34_1",
  microsite_homepage_35: "microsite_homepage_35",
  microsite_homepage_36: "microsite_homepage_36",
  microsite_homepage_36_1: "microsite_homepage_36_1",
  microsite_homepage_37: "microsite_homepage_37",
  microsite_homepage_37_1: "microsite_homepage_37_1",
  microsite_homepage_38: "microsite_homepage_38",
  microsite_homepage_38_1: "microsite_homepage_38_1",
  microsite_homepage_38_2: "microsite_homepage_38_2",
  microsite_homepage_39: "microsite_homepage_39",
  microsite_homepage_39_1: "microsite_homepage_39_1",
  microsite_homepage_40: "microsite_homepage_40",
  microsite_homepage_40_1: "microsite_homepage_40_1",
  microsite_homepage_40_2: "microsite_homepage_40_2",
  microsite_homepage_40_3: "microsite_homepage_40_3",
  microsite_homepage_41: "microsite_homepage_41",
  microsite_homepage_41_1: "microsite_homepage_41_1",
  microsite_homepage_42: "microsite_homepage_42",
  microsite_homepage_43: "microsite_homepage_43",
  microsite_homepage_43_1: "microsite_homepage_43_1",
  microsite_homepage_44: "microsite_homepage_44",
  microsite_homepage_45: "microsite_homepage_45",
  microsite_homepage_46: "microsite_homepage_46",
  microsite_homepage_47: "microsite_homepage_47",
  microsite_homepage_47_1: "microsite_homepage_47_1",
  microsite_homepage_48: "microsite_homepage_48",
  microsite_homepage_49: "microsite_homepage_49",
  microsite_homepage_50: "microsite_homepage_50",
  microsite_homepage_51: "microsite_homepage_51",
  microsite_homepage_52: "microsite_homepage_52",
  microsite_homepage_53: "microsite_homepage_53",
  microsite_homepage_54: "microsite_homepage_54",
  microsite_homepage_55: "microsite_homepage_55",
  microsite_homepage_55_1: "microsite_homepage_55_1",
  microsite_homepage_55_1_1: "microsite_homepage_55_1_1",
  microsite_homepage_55_2: "microsite_homepage_55_2",
  microsite_homepage_55_3: "microsite_homepage_55_3",
  microsite_homepage_56: "microsite_homepage_56",
  microsite_homepage_57: "microsite_homepage_57",
  microsite_homepage_57_1: "microsite_homepage_57_1",
  microsite_homepage_58: "microsite_homepage_58",
  microsite_homepage_58_1: "microsite_homepage_58_1",
  microsite_homepage_59: "microsite_homepage_59",
  microsite_homepage_59_1: "microsite_homepage_59_1",
  microsite_homepage_60: "microsite_homepage_60",
  microsite_homepage_60_1: "microsite_homepage_60_1",
  microsite_homepage_60_2: "microsite_homepage_60_2",
  microsite_homepage_60_3: "microsite_homepage_60_3",
  microsite_homepage_61: "microsite_homepage_61",
  microsite_homepage_61_1: "microsite_homepage_61_1",
  microsite_homepage_62: "microsite_homepage_62",
  microsite_homepage_62_1: "microsite_homepage_62_1",
  microsite_homepage_62_1_1: "microsite_homepage_62_1_1",
  microsite_homepage_63: "microsite_homepage_63",
  microsite_homepage_63_1: "microsite_homepage_63_1",
  microsite_homepage_64: "microsite_homepage_64",
  microsite_homepage_64_1: "microsite_homepage_64_1",
  microsite_homepage_65: "microsite_homepage_65",
  microsite_homepage_65_1: "microsite_homepage_65_1",
  microsite_homepage_66: "microsite_homepage_66",
  microsite_homepage_66_1: "microsite_homepage_66_1",
  microsite_homepage_67: "microsite_homepage_67",
  microsite_homepage_67_1: "microsite_homepage_67_1",
  microsite_homepage_68: "microsite_homepage_68",
  microsite_homepage_68_1: "microsite_homepage_68_1",
  microsite_homepage_68_1_1: "microsite_homepage_68_1_1",
  microsite_homepage_69: "microsite_homepage_69",
  microsite_homepage_69_1: "microsite_homepage_69_1",
  microsite_homepage_69_2: "microsite_homepage_69_2",
  microsite_homepage_70: "microsite_homepage_70",
  microsite_homepage_71: "microsite_homepage_71",
  microsite_homepage_71_1: "microsite_homepage_71_1",
  microsite_homepage_72: "microsite_homepage_72",
  microsite_homepage_72_1: "microsite_homepage_72_1",
  microsite_homepage_73: "microsite_homepage_73",
  microsite_homepage_73_1: "microsite_homepage_73_1",
  microsite_homepage_74: "microsite_homepage_74",
  microsite_homepage_74_1: "microsite_homepage_74_1",
  microsite_homepage_75: "microsite_homepage_75",
  microsite_homepage_75_1: "microsite_homepage_75_1",
  microsite_homepage_76: "microsite_homepage_76",
  microsite_homepage_76_1: "microsite_homepage_76_1",
  microsite_homepage_77: "microsite_homepage_77",
  microsite_homepage_77_1: "microsite_homepage_77_1",
  microsite_homepage_78: "microsite_homepage_78",
  microsite_homepage_78_1: "microsite_homepage_78_1",
  microsite_homepage_79: "microsite_homepage_79",
  microsite_homepage_79_1: "microsite_homepage_79_1",
  microsite_homepage_80: "microsite_homepage_80",
  microsite_homepage_80_1: "microsite_homepage_80_1",
  microsite_homepage_81: "microsite_homepage_81",
  microsite_homepage_81_1: "microsite_homepage_81_1",
  microsite_homepage_81_2: "microsite_homepage_81_2",
  microsite_homepage_81_3: "microsite_homepage_81_3",
  microsite_homepage_82: "microsite_homepage_82",
  microsite_homepage_83: "microsite_homepage_83",
  microsite_homepage_84: "microsite_homepage_84",
  microsite_homepage_84_1: "microsite_homepage_84_1",
  microsite_homepage_85: "microsite_homepage_85",
  microsite_homepage_85_1: "microsite_homepage_85_1",
  microsite_homepage_86: "microsite_homepage_86",
  microsite_homepage_86_1: "microsite_homepage_86_1",
  microsite_homepage_87: "microsite_homepage_87",
  microsite_homepage_88: "microsite_homepage_88",
  microsite_homepage_89: "microsite_homepage_89",
  microsite_homepage_90: "microsite_homepage_90",
  microsite_homepage_90_1: "microsite_homepage_90_1",
  microsite_homepage_90_2: "microsite_homepage_90_2",
  microsite_homepage_90_3: "microsite_homepage_90_3",
  microsite_homepage_90_4: "microsite_homepage_90_4",
  microsite_homepage_90_5: "microsite_homepage_90_5",
  microsite_homepage_90_6: "microsite_homepage_90_6",
  microsite_homepage_90_7: "microsite_homepage_90_7",
  microsite_homepage_91: "microsite_homepage_91",
  microsite_homepage_92: "microsite_homepage_92",
  microsite_homepage_93: "microsite_homepage_93",
  microsite_homepage_94: "microsite_homepage_94",
  microsite_homepage_94_1: "microsite_homepage_94_1",
  microsite_homepage_94_2: "microsite_homepage_94_2",
  microsite_homepage_94_3: "microsite_homepage_94_3",
  microsite_homepage_95: "microsite_homepage_95",
  microsite_homepage_96: "microsite_homepage_96",
  microsite_homepage_96_1: "microsite_homepage_96_1",
  microsite_homepage_96_2: "microsite_homepage_96_2",
  microsite_homepage_97: "microsite_homepage_97",
  microsite_homepage_98: "microsite_homepage_98",
  microsite_homepage_99: "microsite_homepage_99",
  microsite_homepage_video: "microsite_homepage_video",
  microsite_homepage_trophy_team: "microsite_homepage_trophy_team",
  microsite_homepage_trophy_team_1: "microsite_homepage_trophy_team_1",
  microsite_homepage_trophy_team_2: "microsite_homepage_trophy_team_2",
  microsite_homepage_trophy_individual: "microsite_homepage_trophy_individual",
  microsite_homepage_trophy_individual_1:
    "microsite_homepage_trophy_individual_1",
  microsite_homepage_trophy_individual_2:
    "microsite_homepage_trophy_individual_2",
  microsite_homepage_advanced_kit: "microsite_homepage_advanced_kit",
  microsite_homepage_basic_kit: "microsite_homepage_basic_kit",

  microsite_submit_2: "microsite_submit_2",
  microsite_submit_3: "microsite_submit_3",
  microsite_submit_3_1: "microsite_submit_3_1",
  microsite_submit_4: "microsite_submit_4",
  microsite_submit_5: "microsite_submit_5",
  microsite_submit_6: "microsite_submit_6",
  microsite_submit_6_1: "microsite_submit_6_1",
  microsite_submit_7: "microsite_submit_7",
  microsite_submit_7_1: "microsite_submit_7_1",
  microsite_submit_8: "microsite_submit_8",
  microsite_submit_8_1: "microsite_submit_8_1",
  microsite_submit_9: "microsite_submit_9",
  microsite_submit_9_1: "microsite_submit_9_1",
  microsite_submit_10: "microsite_submit_10",
  microsite_submit_10_1: "microsite_submit_10_1",
  microsite_submit_11: "microsite_submit_11",
  microsite_submit_11_1: "microsite_submit_11_1",
  microsite_submit_12: "microsite_submit_12",
  microsite_submit_12_1: "microsite_submit_12_1",
  microsite_submit_13: "microsite_submit_13",
  microsite_submit_13_1: "microsite_submit_13_1",
  microsite_submit_14: "microsite_submit_14",
  microsite_submit_14_1: "microsite_submit_14_1",
  microsite_submit_15: "microsite_submit_15",
  microsite_submit_15_1: "microsite_submit_15_1",
  microsite_submit_16: "microsite_submit_16",
  microsite_submit_16_1: "microsite_submit_16_1",
  microsite_submit_17: "microsite_submit_17",
  microsite_submit_18: "microsite_submit_18",
  microsite_submit_18_1: "microsite_submit_18_1",
  microsite_submit_19: "microsite_submit_19",
  microsite_submit_19_1: "microsite_submit_19_1",
  microsite_submit_20: "microsite_submit_20",
  microsite_submit_20_1: "microsite_submit_20_1",
  microsite_submit_21: "microsite_submit_21",
  microsite_submit_22: "microsite_submit_22",
  microsite_submit_22_1: "microsite_submit_22_1",
  microsite_submit_23: "microsite_submit_23",
  microsite_submit_24: "microsite_submit_24",
  microsite_submit_24_1: "microsite_submit_24_1",
  microsite_submit_24_2: "microsite_submit_24_2",
  microsite_submit_25: "microsite_submit_25",
  microsite_submit_26: "microsite_submit_26",
  microsite_submit_26_1: "microsite_submit_26_1",
  microsite_submit_27: "microsite_submit_27",
  microsite_submit_28: "microsite_submit_28",
  microsite_submit_28_1: "microsite_submit_28_1",
  microsite_submit_29: "microsite_submit_29",
  microsite_submit_30: "microsite_submit_30",
  microsite_submit_31: "microsite_submit_31",
  microsite_submit_32: "microsite_submit_32",
  microsite_submit_33: "microsite_submit_33",
  microsite_submit_34: "microsite_submit_34",
  microsite_submit_35: "microsite_submit_35",
  microsite_submit_36: "microsite_submit_36",
  microsite_submit_36_1: "microsite_submit_36_1",
  microsite_submit_36_2: "microsite_submit_36_2",
  microsite_submit_36_3: "microsite_submit_36_3",
  microsite_submit_36_4: "microsite_submit_36_4",
  microsite_submit_36_5: "microsite_submit_36_5",
  microsite_submit_36_6: "microsite_submit_36_6",
  microsite_submit_37: "microsite_submit_37",
  microsite_submit_38: "microsite_submit_38",
  microsite_submit_39: "microsite_submit_39",
  microsite_submit_40: "microsite_submit_40",
  microsite_submit_41: "microsite_submit_41",
  microsite_submit_41_1: "microsite_submit_41_1",
  microsite_submit_42: "microsite_submit_42",
  microsite_submit_42_1: "microsite_submit_42_1",
  microsite_submit_43: "microsite_submit_43",
  microsite_submit_44: "microsite_submit_44",
  microsite_submit_45: "microsite_submit_45",
  microsite_submit_46: "microsite_submit_46",
  microsite_submit_47: "microsite_submit_47",
  microsite_submit_48: "microsite_submit_48",
  microsite_submit_49: "microsite_submit_49",
  microsite_submit_50: "microsite_submit_50",
  microsite_submit_51: "microsite_submit_51",
  microsite_submit_51_1: "microsite_submit_51_1",
  microsite_submit_51_2: "microsite_submit_51_2",
  microsite_submit_51_3: "microsite_submit_51_3",
  microsite_submit_51_4: "microsite_submit_51_4",
  microsite_submit_51_5: "microsite_submit_51_5",
  microsite_submit_51_6: "microsite_submit_51_6",
  microsite_submit_52: "microsite_submit_52",
  microsite_submit_52_1: "microsite_submit_52_1",
  microsite_submit_52_2: "microsite_submit_52_2",
  microsite_submit_53: "microsite_submit_53",
  microsite_submit_54: "microsite_submit_54",
  microsite_submit_55: "microsite_submit_55",
  microsite_submit_56: "microsite_submit_56",
  microsite_submit_57: "microsite_submit_57",
  microsite_submit_58: "microsite_submit_58",
  microsite_submit_59: "microsite_submit_59",
  microsite_submit_60: "microsite_submit_60",
  microsite_submit_61: "microsite_submit_61",
  microsite_submit_62: "microsite_submit_62",
  microsite_submit_63: "microsite_submit_63",
  microsite_submit_64: "microsite_submit_64",
  microsite_submit_65: "microsite_submit_65",
  microsite_submit_66: "microsite_submit_66",

  microsite_term_condition_1: "microsite_term_condition_1",
  microsite_term_condition_2: "microsite_term_condition_2",
  microsite_term_condition_3: "microsite_term_condition_3",
  microsite_term_condition_4: "microsite_term_condition_4",
  microsite_term_condition_5: "microsite_term_condition_5",
  microsite_term_condition_6: "microsite_term_condition_6",
  microsite_term_condition_7: "microsite_term_condition_7",
  microsite_term_condition_8: "microsite_term_condition_8",
  microsite_term_condition_9: "microsite_term_condition_9",
  microsite_term_condition_9_1: "microsite_term_condition_9_1",
  microsite_term_condition_9_2: "microsite_term_condition_9_2",
  microsite_term_condition_9_2_1: "microsite_term_condition_9_2_1",
  microsite_term_condition_9_2_2: "microsite_term_condition_9_2_2",
  microsite_term_condition_9_2_3: "microsite_term_condition_9_2_3",
  microsite_term_condition_9_2_4: "microsite_term_condition_9_2_4",
  microsite_term_condition_10: "microsite_term_condition_10",
  microsite_term_condition_11: "microsite_term_condition_11",
  microsite_term_condition_12: "microsite_term_condition_12",
  microsite_term_condition_13: "microsite_term_condition_13",
  microsite_term_condition_13_1: "microsite_term_condition_13_1",
  microsite_term_condition_13_1_1: "microsite_term_condition_13_1_1",
  microsite_term_condition_13_1_2: "microsite_term_condition_13_1_2",
  microsite_term_condition_13_2: "microsite_term_condition_13_2",
  microsite_term_condition_13_3: "microsite_term_condition_13_3",
  microsite_term_condition_13_4: "microsite_term_condition_13_4",
  microsite_term_condition_13_5: "microsite_term_condition_13_5",
  microsite_term_condition_14: "microsite_term_condition_14",
  microsite_term_condition_15: "microsite_term_condition_15",
  microsite_term_condition_16: "microsite_term_condition_16",
  microsite_term_condition_17: "microsite_term_condition_17",
  microsite_term_condition_17_1: "microsite_term_condition_17_1",
  microsite_term_condition_17_2: "microsite_term_condition_17_2",
  microsite_term_condition_17_3: "microsite_term_condition_17_3",
  microsite_term_condition_17_4: "microsite_term_condition_17_4",

  microsite_register_1: "microsite_register_1",
  microsite_register_2: "microsite_register_2",
  microsite_register_3: "microsite_register_3",
  microsite_register_4: "microsite_register_4",
  microsite_register_5: "microsite_register_5",
  microsite_register_6: "microsite_register_6",
  microsite_register_7: "microsite_register_7",
  microsite_register_7_1: "microsite_register_7_1",
  microsite_register_8: "microsite_register_8",
  microsite_register_8_1: "microsite_register_8_1",
  microsite_register_9: "microsite_register_9",
  microsite_register_9_1: "microsite_register_9_1",
  microsite_register_10: "microsite_register_10",
  microsite_register_10_1: "microsite_register_10_1",
  microsite_register_11: "microsite_register_11",
  microsite_register_11_1: "microsite_register_11_1",
  microsite_register_12: "microsite_register_12",
  microsite_register_12_1: "microsite_register_12_1",
  microsite_register_13: "microsite_register_13",
  microsite_register_13_1: "microsite_register_13_1",
  microsite_register_14: "microsite_register_14",
  microsite_register_14_1: "microsite_register_14_1",
  microsite_register_15: "microsite_register_15",
  microsite_register_15_1: "microsite_register_15_1",
  microsite_register_16: "microsite_register_16",
  microsite_register_17: "microsite_register_17",
  microsite_register_17_1: "microsite_register_17_1",
  microsite_register_18: "microsite_register_18",
  microsite_register_18_1: "microsite_register_18_1",
  microsite_register_18_2: "microsite_register_18_2",
  microsite_register_19: "microsite_register_19",
  microsite_register_19_1_1_1: "microsite_register_19_1_1_1",
  microsite_register_19_1_1_2: "microsite_register_19_1_1_2",
  microsite_register_20: "microsite_register_20",
  microsite_register_21: "microsite_register_21",
  microsite_register_22: "microsite_register_22",
  microsite_register_23: "microsite_register_23",
  microsite_register_24: "microsite_register_24",
  microsite_register_25: "microsite_register_25",
  microsite_register_26: "microsite_register_26",
  microsite_register_27: "microsite_register_27",
  microsite_register_28: "microsite_register_28",
  microsite_register_29: "microsite_register_29",
  microsite_register_30: "microsite_register_30",
  microsite_register_31: "microsite_register_31",

  microsite_contact_us_1: "microsite_contact_us_1",
  microsite_contact_us_2: "microsite_contact_us_2",
  microsite_contact_us_3: "microsite_contact_us_3",
  microsite_contact_us_4: "microsite_contact_us_4",
  microsite_contact_us_5: "microsite_contact_us_5",
  microsite_contact_us_6: "microsite_contact_us_6",
  microsite_contact_us_7: "microsite_contact_us_7",
  microsite_contact_us_8: "microsite_contact_us_8",
  microsite_contact_us_9: "microsite_contact_us_9",
  microsite_contact_us_10: "microsite_contact_us_10",
  microsite_contact_us_11: "microsite_contact_us_11",
  microsite_contact_us_12: "microsite_contact_us_12",
  microsite_contact_us_13: "microsite_contact_us_13",
  microsite_contact_us_14: "microsite_contact_us_14",
  microsite_contact_us_15: "microsite_contact_us_15",
  microsite_contact_us_16: "microsite_contact_us_16",
  microsite_contact_us_17: "microsite_contact_us_17",
  microsite_contact_us_18: "microsite_contact_us_18",
  microsite_contact_us_19: "microsite_contact_us_19",
  microsite_contact_us_20: "microsite_contact_us_20",
  microsite_contact_us_21: "microsite_contact_us_21",
  microsite_contact_us_22: "microsite_contact_us_22",
  microsite_contact_us_23: "microsite_contact_us_23",
  microsite_contact_us_24: "microsite_contact_us_24",
  microsite_contact_us_24_1: "microsite_contact_us_24_1",
  microsite_contact_us_24_2: "microsite_contact_us_24_2",
  microsite_contact_us_24_3: "microsite_contact_us_24_3",
  microsite_contact_us_25: "microsite_contact_us_25",
  microsite_contact_us_26: "microsite_contact_us_26",
  microsite_contact_us_27: "microsite_contact_us_27",

  microsite_footer_1: "microsite_footer_1",
  microsite_footer_2: "microsite_footer_2",
  microsite_footer_2_1: "microsite_footer_2_1",
  microsite_footer_3: "microsite_footer_3",
  microsite_footer_4: "microsite_footer_4",
  microsite_footer_5: "microsite_footer_5",
  microsite_footer_6: "microsite_footer_6",
  microsite_footer_6_1: "microsite_footer_6_1",
  microsite_footer_7: "microsite_footer_7",
  microsite_footer_8: "microsite_footer_8",
  microsite_footer_9: "microsite_footer_9",
  microsite_footer_10: "microsite_footer_10",

  microsite_faq_2: "microsite_faq_2",
  microsite_faq_3: "microsite_faq_3",
  microsite_faq_4: "microsite_faq_4",
  microsite_faq_5: "microsite_faq_5",
  microsite_faq_5_1: "microsite_faq_5_1",
  microsite_faq_5_1_1: "microsite_faq_5_1_1",
  microsite_faq_5_2: "microsite_faq_5_2",
  microsite_faq_5_2_1: "microsite_faq_5_2_1",
  microsite_faq_5_3: "microsite_faq_5_3",
  microsite_faq_5_3_1: "microsite_faq_5_3_1",
  microsite_faq_5_4: "microsite_faq_5_4",
  microsite_faq_5_5: "microsite_faq_5_5",
  microsite_faq_5_6: "microsite_faq_5_6",
  microsite_faq_5_7: "microsite_faq_5_7",
  microsite_faq_5_7_1: "microsite_faq_5_7_1",
  microsite_faq_5_8: "microsite_faq_5_8",
  microsite_faq_5_9: "microsite_faq_5_9",
  microsite_faq_5_10: "microsite_faq_5_10",
  microsite_faq_5_10_1: "microsite_faq_5_10_1",
  microsite_faq_5_11: "microsite_faq_5_11",
  microsite_faq_6: "microsite_faq_6",
  microsite_faq_6_1: "microsite_faq_6_1",
  microsite_faq_6_1_1: "microsite_faq_6_1_1",
  microsite_faq_6_1_1_1: "microsite_faq_6_1_1_1",
  microsite_faq_6_1_2: "microsite_faq_6_1_2",
  microsite_faq_6_2: "microsite_faq_6_2",
  microsite_faq_6_2_1: "microsite_faq_6_2_1",
  microsite_faq_6_3: "microsite_faq_6_3",
  microsite_faq_6_4: "microsite_faq_6_4",
  microsite_faq_6_5: "microsite_faq_6_5",
  microsite_faq_6_5_1: "microsite_faq_6_5_1",
  microsite_faq_6_6: "microsite_faq_6_6",
  microsite_faq_6_6_1: "microsite_faq_6_6_1",
  microsite_faq_6_7: "microsite_faq_6_7",
  microsite_faq_6_8: "microsite_faq_6_8",
  microsite_faq_6_9: "microsite_faq_6_9",
  microsite_faq_7: "microsite_faq_7",
  microsite_faq_7_1: "microsite_faq_7_1",
  microsite_faq_8: "microsite_faq_8",
  microsite_faq_8_1: "microsite_faq_8_1",
  microsite_faq_8_2: "microsite_faq_8_2",
  microsite_faq_8_3: "microsite_faq_8_3",
  microsite_faq_8_4: "microsite_faq_8_4",
  microsite_faq_8_5: "microsite_faq_8_5",
  microsite_faq_9: "microsite_faq_9",
  microsite_faq_9_1: "microsite_faq_9_1",
  microsite_faq_10: "microsite_faq_10",
  microsite_faq_10_1: "microsite_faq_10_1",
  microsite_faq_11: "microsite_faq_11",
  microsite_faq_11_1: "microsite_faq_11_1",
  microsite_faq_11_2: "microsite_faq_11_2",
  microsite_faq_11_3: "microsite_faq_11_3",
  microsite_faq_12: "microsite_faq_12",
  microsite_faq_12_1: "microsite_faq_12_1",
  microsite_faq_12_2: "microsite_faq_12_2",
  microsite_faq_13: "microsite_faq_13",
  microsite_faq_13_1: "microsite_faq_13_1",
  microsite_faq_13_2: "microsite_faq_13_2",
  microsite_faq_13_3: "microsite_faq_13_3",
  microsite_faq_14: "microsite_faq_14",
  microsite_faq_14_1: "microsite_faq_14_1",
  microsite_faq_14_2: "microsite_faq_14_2",
  microsite_faq_15: "microsite_faq_15",
  microsite_faq_16: "microsite_faq_16",
  microsite_faq_17: "microsite_faq_17",
  microsite_faq_18: "microsite_faq_18",
  microsite_faq_19: "microsite_faq_19",
  microsite_faq_20: "microsite_faq_20",
  microsite_faq_21: "microsite_faq_21",
  microsite_faq_22: "microsite_faq_22",
  microsite_faq_23: "microsite_faq_23",
  microsite_faq_24: "microsite_faq_24",
  microsite_faq_25: "microsite_faq_25",
  microsite_faq_26: "microsite_faq_26",
  microsite_faq_27: "microsite_faq_27",
  microsite_faq_28: "microsite_faq_28",
  microsite_faq_29: "microsite_faq_29",
  microsite_faq_30: "microsite_faq_30",
  microsite_faq_31: "microsite_faq_31",
  microsite_faq_32: "microsite_faq_32",
  microsite_faq_33: "microsite_faq_33",
  microsite_faq_34: "microsite_faq_34",
  microsite_faq_35: "microsite_faq_35",
  microsite_faq_36: "microsite_faq_36",
  microsite_faq_37: "microsite_faq_37",
  microsite_faq_38: "microsite_faq_38",
  microsite_faq_39: "microsite_faq_39",
  microsite_faq_40: "microsite_faq_40",
  microsite_faq_41: "microsite_faq_41",
  microsite_faq_42: "microsite_faq_42",
  microsite_faq_43: "microsite_faq_43",
  microsite_faq_44: "microsite_faq_44",
  microsite_faq_45: "microsite_faq_45",
  microsite_faq_46: "microsite_faq_46",
  microsite_faq_47: "microsite_faq_47",
  microsite_faq_48: "microsite_faq_48",
  microsite_faq_49: "microsite_faq_49",
  microsite_faq_50: "microsite_faq_50",
  microsite_faq_51: "microsite_faq_51",
  microsite_faq_52: "microsite_faq_52",
  microsite_faq_53: "microsite_faq_53",
  microsite_faq_54: "microsite_faq_54",
  microsite_faq_55: "microsite_faq_55",
  microsite_faq_56: "microsite_faq_56",
  microsite_faq_57: "microsite_faq_57",
  microsite_faq_58: "microsite_faq_58",
  microsite_faq_59: "microsite_faq_59",
  microsite_faq_60: "microsite_faq_60",
  microsite_faq_61: "microsite_faq_61",
  microsite_faq_62: "microsite_faq_62",
  microsite_faq_63: "microsite_faq_63",
  microsite_faq_64: "microsite_faq_64",
  microsite_faq_65: "microsite_faq_65",
  microsite_faq_66: "microsite_faq_66",
  microsite_faq_67: "microsite_faq_67",
  microsite_faq_68: "microsite_faq_68",
  microsite_faq_69: "microsite_faq_69",
  microsite_faq_70: "microsite_faq_70",
  microsite_faq_71: "microsite_faq_71",
  microsite_faq_72: "microsite_faq_72",
  microsite_faq_73: "microsite_faq_73",
  microsite_faq_74: "microsite_faq_74",
  microsite_faq_75: "microsite_faq_75",
  microsite_faq_76: "microsite_faq_76",
  microsite_faq_77: "microsite_faq_77",
  microsite_faq_78: "microsite_faq_78",
  microsite_faq_79: "microsite_faq_79",
  microsite_faq_80: "microsite_faq_80",
  microsite_faq_81: "microsite_faq_81",
  microsite_faq_82: "microsite_faq_82",
  microsite_faq_83: "microsite_faq_83",
  microsite_faq_84: "microsite_faq_84",
  microsite_faq_85: "microsite_faq_85",
  microsite_faq_86: "microsite_faq_86",
  microsite_faq_87: "microsite_faq_87",
  microsite_faq_88: "microsite_faq_88",
  microsite_faq_89: "microsite_faq_89",
  microsite_faq_90: "microsite_faq_90",
  microsite_faq_91: "microsite_faq_91",
  microsite_faq_92: "microsite_faq_92",
  microsite_faq_93: "microsite_faq_93",
  microsite_faq_94: "microsite_faq_94",
  microsite_faq_95: "microsite_faq_95",
  microsite_faq_96: "microsite_faq_96",
  microsite_faq_96_1: "microsite_faq_96_1",
  microsite_faq_97: "microsite_faq_97",
  microsite_faq_98: "microsite_faq_98",
  microsite_faq_99: "microsite_faq_99",
  microsite_faq_100: "microsite_faq_100",
  microsite_faq_101: "microsite_faq_101",
  microsite_faq_102: "microsite_faq_102",
  microsite_faq_103: "microsite_faq_103",
  microsite_faq_104: "microsite_faq_104",
  microsite_faq_105: "microsite_faq_105",
  microsite_faq_106: "microsite_faq_106",
  microsite_faq_107: "microsite_faq_107",
  microsite_faq_108: "microsite_faq_108",
  microsite_faq_109: "microsite_faq_109",
  microsite_faq_110: "microsite_faq_110",
  microsite_faq_111: "microsite_faq_111",
  microsite_faq_112: "microsite_faq_112",
  microsite_faq_113: "microsite_faq_113",
  microsite_faq_114: "microsite_faq_114",
  microsite_faq_115: "microsite_faq_115",
  microsite_faq_116: "microsite_faq_116",
  microsite_faq_117: "microsite_faq_117",
  microsite_faq_118: "microsite_faq_118",
  microsite_faq_119: "microsite_faq_119",
  microsite_faq_120: "microsite_faq_120",
  microsite_faq_121: "microsite_faq_121",
  microsite_faq_122: "microsite_faq_122",
  microsite_faq_123: "microsite_faq_123",
  microsite_faq_123_1: "microsite_faq_123_1",
  microsite_faq_124: "microsite_faq_124",
  microsite_faq_125: "microsite_faq_125",
  microsite_faq_126: "microsite_faq_126",
  microsite_faq_127: "microsite_faq_127",
  microsite_faq_128: "microsite_faq_128",
  microsite_faq_129: "microsite_faq_129",
  microsite_faq_130: "microsite_faq_130",
  microsite_faq_131: "microsite_faq_131",
  microsite_faq_132: "microsite_faq_132",
  microsite_faq_133: "microsite_faq_133",
  microsite_faq_134: "microsite_faq_134",
  microsite_faq_135: "microsite_faq_135",
  microsite_faq_136: "microsite_faq_136",
  microsite_faq_137: "microsite_faq_137",
  microsite_faq_138: "microsite_faq_138",
  microsite_faq_139: "microsite_faq_139",
  microsite_faq_140: "microsite_faq_140",
  microsite_faq_141: "microsite_faq_141",
  microsite_faq_142: "microsite_faq_142",
  microsite_faq_143: "microsite_faq_143",
  microsite_faq_144: "microsite_faq_144",
  microsite_faq_145: "microsite_faq_145",
  microsite_faq_146: "microsite_faq_146",
  microsite_faq_147: "microsite_faq_147",
  microsite_faq_148: "microsite_faq_148",
  microsite_faq_148_1: "microsite_faq_148_1",
  microsite_faq_148_2: "microsite_faq_148_2",
  microsite_faq_148_3: "microsite_faq_148_3",
  microsite_faq_149: "microsite_faq_149",
  microsite_faq_150: "microsite_faq_150",
  microsite_faq_151: "microsite_faq_151",
  microsite_faq_152: "microsite_faq_152",
  microsite_faq_152_1: "microsite_faq_152_1",
  microsite_faq_153: "microsite_faq_153",
  microsite_faq_154: "microsite_faq_154",
  microsite_faq_155: "microsite_faq_155",
  microsite_faq_156: "microsite_faq_156",
  microsite_faq_157: "microsite_faq_157",
  microsite_faq_157_1: "microsite_faq_157_1",
  microsite_faq_157_2: "microsite_faq_157_2",
  microsite_faq_157_3: "microsite_faq_157_3",
  microsite_faq_157_4: "microsite_faq_157_4",
  microsite_faq_158: "microsite_faq_158",
  microsite_faq_159: "microsite_faq_159",
  microsite_faq_160: "microsite_faq_160",
  microsite_faq_161: "microsite_faq_161",
  microsite_faq_162: "microsite_faq_162",
  microsite_faq_163: "microsite_faq_163",
  microsite_faq_164: "microsite_faq_164",
  microsite_faq_165: "microsite_faq_165",
  microsite_faq_166: "microsite_faq_166",
  microsite_faq_167: "microsite_faq_167",
  microsite_faq_168: "microsite_faq_168",
  microsite_faq_169: "microsite_faq_169",
  microsite_faq_170: "microsite_faq_170",
  microsite_faq_171: "microsite_faq_171",
  microsite_faq_172: "microsite_faq_172",
  microsite_faq_173: "microsite_faq_173",
  microsite_faq_174: "microsite_faq_174",
  microsite_faq_175: "microsite_faq_175",
  microsite_faq_176: "microsite_faq_176",
  microsite_faq_177: "microsite_faq_177",
  microsite_faq_178: "microsite_faq_178",
  microsite_faq_179: "microsite_faq_179",
  microsite_faq_180: "microsite_faq_180",
  microsite_faq_181: "microsite_faq_181",
  microsite_faq_182: "microsite_faq_182",
  microsite_faq_183: "microsite_faq_183",
  microsite_faq_184: "microsite_faq_184",
  microsite_faq_185: "microsite_faq_185",
  microsite_faq_186: "microsite_faq_186",
  microsite_faq_187: "microsite_faq_187",
  microsite_faq_188: "microsite_faq_188",
  microsite_faq_189: "microsite_faq_189",
  microsite_faq_189_1: "microsite_faq_189_1",
  microsite_faq_190: "microsite_faq_190",
  microsite_faq_191: "microsite_faq_191",
  microsite_faq_192: "microsite_faq_192",
  microsite_faq_193: "microsite_faq_193",
  microsite_faq_194: "microsite_faq_194",
  microsite_faq_195: "microsite_faq_195",
  microsite_faq_196: "microsite_faq_196",
  microsite_faq_197: "microsite_faq_197",
  microsite_faq_198: "microsite_faq_198",
  microsite_faq_199: "microsite_faq_199",
  microsite_faq_200: "microsite_faq_200",
  microsite_faq_201: "microsite_faq_201",
  microsite_faq_202: "microsite_faq_202",
  microsite_faq_203: "microsite_faq_203",
  microsite_faq_204: "microsite_faq_204",
  microsite_faq_205: "microsite_faq_205",
  microsite_faq_206: "microsite_faq_206",
  microsite_faq_207: "microsite_faq_207",
  microsite_faq_208: "microsite_faq_208",
  microsite_faq_209: "microsite_faq_209",
  microsite_faq_210: "microsite_faq_210",
  microsite_faq_211: "microsite_faq_211",
  microsite_faq_212: "microsite_faq_212",
  microsite_faq_212_1: "microsite_faq_212_1",
  microsite_faq_213: "microsite_faq_213",
  microsite_faq_214: "microsite_faq_214",
  microsite_faq_215: "microsite_faq_215",
  microsite_faq_216: "microsite_faq_216",
  microsite_faq_217: "microsite_faq_217",
  microsite_faq_218: "microsite_faq_218",
  microsite_faq_219: "microsite_faq_219",
  microsite_faq_220: "microsite_faq_220",
  microsite_faq_221: "microsite_faq_221",
  microsite_faq_222: "microsite_faq_222",
  microsite_faq_223: "microsite_faq_223",
  microsite_faq_223_1_1: "microsite_faq_223_1_1",
  microsite_faq_223_1: "microsite_faq_223_1",
  microsite_faq_223_2: "microsite_faq_223_2",
  microsite_faq_223_3: "microsite_faq_223_3",
  microsite_faq_224: "microsite_faq_224",
  microsite_faq_124_1: "microsite_faq_124_1",
  microsite_faq_225: "microsite_faq_225",
  microsite_faq_225_1: "microsite_faq_225_1",
  microsite_faq_225_2: "microsite_faq_225_2",
  microsite_faq_225_3: "microsite_faq_225_3",
  microsite_faq_225_4: "microsite_faq_225_4",
  microsite_faq_225_5: "microsite_faq_225_5",
  microsite_faq_225_6: "microsite_faq_225_6",
  microsite_faq_225_7: "microsite_faq_225_7",
  microsite_faq_226: "microsite_faq_226",
  microsite_faq_227: "microsite_faq_227",
  microsite_faq_228: "microsite_faq_228",
  microsite_faq_229: "microsite_faq_229",
  microsite_faq_230: "microsite_faq_230",
  microsite_faq_231: "microsite_faq_231",
  microsite_faq_232: "microsite_faq_232",
  microsite_faq_233: "microsite_faq_233",
  microsite_faq_234: "microsite_faq_234",
  microsite_faq_235: "microsite_faq_235",
  microsite_faq_236: "microsite_faq_236",
  microsite_faq_237: "microsite_faq_237",
  microsite_faq_238: "microsite_faq_238",

  microsite_page_not_found_1: "microsite_page_not_found_1",
  microsite_page_not_found_2: "microsite_page_not_found_2",
  microsite_page_not_found_3: "microsite_page_not_found_3",

  landing_page_name: "landing_page_name",
  landing_page_photos: "landing_page_photos",
  landing_page_action: "landing_page_action",
  landing_page_updated_date: "landing_page_updated_date",
  landing_page_view: "landing_page_view",
  landing_page_notice: "landing_page_notice",
  landing_page_notice_content: "landing_page_notice_content",
  landing_page_invalid_image: "landing_page_invalid_image",

  landing_page_action_1: "landing_page_action_1",
  landing_page_action_2: "landing_page_action_2",
  landing_page_action_3: "landing_page_action_3",
  landing_page_action_4: "landing_page_action_4",

  required_info: "required_info",
  enter_invalid_email: "enter_invalid_email",
  check_accept_privacy: "check_accept_privacy",
};
