import axios from "axios";

const forgeryBaseUrl = "cross-site-request-forgery";

const crossSiteRequestForgeryApi = {
  get() {
    return axios.get(process.env.REACT_APP_API + "/v1/" + forgeryBaseUrl);
  },
};

const version = "/v1";
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API + version,
  headers: {
    "Content-Type": "application/json",
    "Origin": process.env.REACT_APP_DOMAIN
  },
  withCredentials: true,
});

axiosClient.interceptors.request.use(
  async function (config) {
    const csrfToken = localStorage.getItem("X-CSRF-TOKEN");
    if (csrfToken) {
      config.headers["X-CSRF-TOKEN"] = csrfToken;
    } else {
      const response = await crossSiteRequestForgeryApi.get();
      const authCsrf = response.headers["x-csrf-token"];
      if (authCsrf) {
        config.headers["X-CSRF-TOKEN"] = authCsrf;
        localStorage.getItem("X-CSRF-TOKEN", authCsrf);
      }
    }
    const country = localStorage.getItem("country")
      ? JSON.parse(localStorage.getItem("country"))
      : undefined;
    const baseURL = country?.api_auth_uri ?? process.env.REACT_APP_API + "/";
    axiosClient.baseURL = baseURL + "v1";
    config.baseURL = baseURL + "v1";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    const csrfToken = response.headers["x-csrf-token"];
    if (csrfToken) {
      localStorage.setItem("X-CSRF-TOKEN", csrfToken);
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default axiosClient;
