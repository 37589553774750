import { useQuery } from "@tanstack/react-query";
import websiteApi from "apis/websiteAPI";
import _ from "lodash";
import queryString from "query-string";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import Loading from "./Loading";

export const LanguageRoute = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = queryString.parse(searchParams.toString());
  const country = JSON.parse(localStorage.getItem("country"));
  const language = JSON.parse(localStorage.getItem("language"));
  let isAccept = !!(country?.id && language?.id);

  const chooseCountryAndLanguagePath = ["/choose-country", "/choose-language"];
  const location = useLocation();
  const isMatch = chooseCountryAndLanguagePath.includes(location.pathname);

  const { isFetching } = useQuery(
    ["get-info-lang", params?.lang],
    () => websiteApi.getInfoLang({ key: params?.lang }),
    {
      enabled: !!params.lang,
      select: (data) => data.data,
      onSuccess: (data) => {
        isAccept = true;
        if (data?.data && Object.values(data.data).length > 0) {
          localStorage.setItem("language", JSON.stringify(data?.data));
        }
        if (
          data?.data?.country &&
          Object.values(data.data.country).length > 0
        ) {
          localStorage.setItem("country", JSON.stringify(data?.data?.country));
        }
        setSearchParams(_.omit(params, ["lang"]));
      },
      retry: 1,
    }
  );

  if (isFetching) return <Loading />;
  return isAccept ? (
    isMatch ? (
      <Navigate
        to={{
          pathname: "/",
        }}
      />
    ) : (
      children
    )
  ) : isMatch ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: "/choose-country",
      }}
    />
  );
};
