import { Box, CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <Box
      textAlign="center"
      padding="50px 0"
      sx={{ maxHeight: "calc(100vh - 320px)", height: '100vh', boxSizing: 'border-box' }}
    >
      <CircularProgress
        title="Loading..."
        alt="Loading..."
        sx={{ alignSelf: "center" }}
        color="primary"
      />
    </Box>
  );
};

export default Loading;
