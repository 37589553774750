import { MenuItem, Paper, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function TabsHeaderMobiles({ handleClose, data }) {
  const { pathname } = useLocation()
    const navigate = useNavigate()
    const handleClick = (e)=>{
        handleClose()
        navigate(e?.url)
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    }
  return (
    <Paper sx={{ maxWidth: 560, width: "100vw", boxShadow: "none" }}>
      {data.map((e,i) => {
        return (
            <MenuItem key={i} onClick={()=> handleClick(e)} style={pathname === e.url ? {background: '#429f46', color: 'white'} : {}}>
              <Typography variant="button" sx={{textTransform: "initial"}}> {e.label}</Typography>
            </MenuItem>
        );
      })}
    </Paper>
  );
}
