import { useEffect, useState } from "react";

const parseResult = (val) => {
  let result;
  try {
    result = JSON.parse(val);
  } catch (error) {
    result = val;
  }
  return result;
};

export default function useLocalStorage(key, initialValue) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue === null ? initialValue : parseResult(storedValue);
  });
  useEffect(() => {
    const listener = (e) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
      }
    };
    window.addEventListener("storage", listener);
    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [key, initialValue]);
  const setValueInLocalStorage = (newValue) => {
    setValue((prevVal) => {
      const result =
        typeof newValue === "function" ? newValue(prevVal) : newValue;
      localStorage.setItem(key, result);
      return result;
    });
  };
  return [value, setValueInLocalStorage];
}
