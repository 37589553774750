import axiosClient from "./axiosClient";

const websiteApi = {
  getAllCountry() {
    const url = "/setup/countries";
    return axiosClient.get(url);
  },
  getAllLanguageByCountry(id) {
    const url = "/setup/countries/"+id+"/languages";
    return axiosClient.get(url);
  },
  getAllContent(idCountry, idLanguage) {
    const url = "/setup/countries/"+idCountry+"/languages/"+idLanguage+'/key-words';
    return axiosClient.get(url);
  },
  postContactUs(data){
    const url = "/contact";
    return axiosClient.post(url,data);
  },
  postContactUsReply(id,data){
    const url = "/contact/" + id + "/reply";
    return axiosClient.post(url,data);
  },
  getInfoLang(params){
    const url = "/setup/countries/languages";
    return axiosClient.get(url,{ params: params});
  },
  getInfoContactUsReply(id){
    const url = "/contact/" + id;
    return axiosClient.get(url);
  }
};

export default websiteApi;
