import "./styles/homepage.css";
import "./styles/headerpage.css";
import "./styles/register.css";
import "./styles/submit.css";
import "./App.css";
import "./index.css";

import "swiper/css";
import "swiper/css/pagination";

import { Route, BrowserRouter, Routes } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import { Loading } from "shared_components";
import { createTheme, ThemeProvider } from "@mui/material";
import PageNotFound from "shared_components/PageNoteFound";
import ScrollToTop from "shared_components/ScrollToTop";
import { LanguageRoute } from "shared_components/LanguageRouter";
import ContactUsLandingPage from "containers/ContactUs-LandingPage/ContactUsLandingPage";

const ContainerLayout = lazy(() => import("layouts/ContainerLayout"));
const Home = lazy(() => import("containers/Home/Home"));
const ConditionCountry = lazy(() =>
  import("containers/Conditions/LayoutChooseCountry")
);
const ConditionLanguage = lazy(() =>
  import("containers/Conditions/LayoutChooseLanguage")
);
const TermsAndConditions = lazy(() =>
  import("containers/TermsAndConditions/TermsAndConditions")
);
const HowToSubmit = lazy(() => import("containers/HowToSubmit/HowToSubmit"));
const HowToRegister = lazy(() =>
  import("containers/HowToRegister/HowToRegister")
);
const FAQs = lazy(() => import("containers/FAQs/FAQs"));
const ContactUs = lazy(() => import("containers/ContactUs/ContactUs"));
const PrivacyAndPolicy = lazy(() =>
  import("containers/PrivacyAndPolicy/PrivacyAndPolicy")
);
const ReleaseOfLiability = lazy(() =>
  import("containers/ReleaseOfLiability/ReleaseOfLiability")
);
const DataDeletion = lazy(() => import("containers/DataDeletion/DataDeletion"));

const theme = createTheme({
  typography: {
    fontFamily: "NotoSans",
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loading />}>
          <BrowserRouter>
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<ContainerLayout />}>
                  <Route
                    path={"/"}
                    element={
                      <LanguageRoute>
                        <Home />
                      </LanguageRoute>
                    }
                  />
                  <Route
                    path={"/release-of-liability"}
                    element={
                      <LanguageRoute>
                        <ReleaseOfLiability />
                      </LanguageRoute>
                    }
                  />
                  <Route
                    path={"/data-deletion"}
                    element={
                      <LanguageRoute>
                        <DataDeletion />
                      </LanguageRoute>
                    }
                  />
                  <Route
                    path={"/privacy-policy"}
                    element={
                      <LanguageRoute>
                        <PrivacyAndPolicy />
                      </LanguageRoute>
                    }
                  />
                  <Route
                    path={"/choose-country"}
                    element={
                      <LanguageRoute>
                        <ConditionCountry />
                      </LanguageRoute>
                    }
                  ></Route>
                  <Route
                    path={"/choose-language"}
                    element={
                      <LanguageRoute>
                        <ConditionLanguage />
                      </LanguageRoute>
                    }
                  ></Route>
                  <Route
                    path={"/terms-and-conditions"}
                    element={
                      <LanguageRoute>
                        <TermsAndConditions />
                      </LanguageRoute>
                    }
                  ></Route>
                  <Route
                    path={"/FAQs"}
                    element={
                      <LanguageRoute>
                        <FAQs />
                      </LanguageRoute>
                    }
                  ></Route>
                  <Route
                    path={"/how-to-submit"}
                    element={
                      <LanguageRoute>
                        <HowToSubmit />
                      </LanguageRoute>
                    }
                  ></Route>
                  <Route
                    path={"/how-to-register"}
                    element={
                      <LanguageRoute>
                        <HowToRegister />
                      </LanguageRoute>
                    }
                  ></Route>
                  <Route
                    path={"/contact-us"}
                    element={
                      <LanguageRoute>
                        <ContactUs />
                      </LanguageRoute>
                    }
                  ></Route>
                  <Route path="*" element={<PageNotFound />} />
                </Route>

                <Route
                  path={"/contact-us-reply"}
                  element={
                    <LanguageRoute>
                      <ContactUsLandingPage />
                    </LanguageRoute>
                  }
                />
              </Routes>
            </ScrollToTop>
          </BrowserRouter>
        </Suspense>
      </ThemeProvider>
    </div>
  );
}
export default App;
