import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import axiosClient from "apis/axiosClient";
import { typeModal } from "constants/contactUsReply";
import { KEY_LANGUAGE } from "constants/language";
import ModalContactUs from "containers/ContactUs/components/ModalContactUs";
import React, { useState } from "react";
import { memo } from "react";
import { getText } from "utils";

// 1kb = 1024 byte
// 1MB = 1024 KB
const sizeImg = 15 * 1024 * 1024; //byte

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#41A04730",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#41A047",
  },
}));

const UploadImage = ({
  objText,
  fileList,
  setFileList,
  handleImgValid = () => null,
}) => {
  const [loading, setLoading] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const country = JSON.parse(localStorage.getItem("country") ?? undefined);

  const uploadSingleFiles = async (e) => {
    const file = e.target.files[0];
    const isApprove =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    const isOversize = file.size > sizeImg;
    if (!isApprove || isOversize || !country) {
      handleImgValid(typeModal.invalidImage.key);
      return;
    }

    setLoading(true);
    const csrfToken = localStorage.getItem("X-CSRF-TOKEN");
    const data = new FormData();
    data.append("file", e.target.files[0]);

    const url = country.api_media_uri + "v1/files/contact";
    await axiosClient
      .post(url, data, {
        headers: {
          "X-CSRF-TOKEN": csrfToken,
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then(({ data }) => {
        setFileList([...fileList, data?.data]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeImage = (index) => {
    setFileList([
      ...fileList.slice(0, index),
      ...fileList.slice(index + 1, fileList.length),
    ]);
  };

  const handleClose = () => {
    setImageUrl("");
  };

  const handleImage = (value) => {
    setImageUrl(value);
  };

  return (
    <Stack
      marginBottom={2}
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 1, sm: 2 }}
      justifyContent="start"
      alignItems="self-start"
    >
      <Typography sx={{ minWidth: "156px" }} fontWeight="bold">
        {getText(objText, KEY_LANGUAGE.landing_page_photos)}
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2 }}
        justifyContent="start"
        alignItems="start"
      >
        {fileList.length !== 0 &&
          fileList.map((e, index) => (
            <Box
              key={e?.id}
              sx={{
                width: 120,
                height: 120,
              }}
            >
              <Box className="img-block bg-gray">
                <img
                  className="img-fluid2"
                  src={e?.uri}
                  alt={e?.file_name}
                  onClick={() => handleImage(e?.uri)}
                />
                <span className="remove_img" onClick={() => removeImage(index)}>
                  X
                </span>
              </Box>
            </Box>
          ))}

        {fileList.length > 2 ? null : (
          <Box
            sx={{
              width: 120,
              height: 120,
            }}
          >
            <Box className="upload-btn-wrapper">
              {loading ? (
                <Box p={1}>
                  <CustomLinearProgress style={{ marginTop: "50%" }} />
                </Box>
              ) : (
                <>
                  <button className="image-btn"> + </button>
                  <input
                    type="file"
                    name="myfile"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={uploadSingleFiles}
                  />
                </>
              )}
            </Box>
          </Box>
        )}
      </Stack>

      <ModalContactUs
        open={imageUrl?.length > 0}
        handleClose={handleClose}
        objText={objText}
        imageUrl={imageUrl}
      />
    </Stack>
  );
};

export default memo(UploadImage);
