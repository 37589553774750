import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import websiteApi from "apis/websiteAPI";
import useCheckParams from "hooks/useCheckParams";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

function SelectLanguage() {
  const navigate = useNavigate();
  const { language_id, country_id } = useCheckParams();

  const { data, isLoading } = useQuery(
    ["language", country_id],
    () => websiteApi.getAllLanguageByCountry(country_id),
    { select: (val) => val.data, enabled: Boolean(country_id) }
  );
  const handleChange = (event) => {
    const result = data?.data?.find((e) => e.id === event?.target.value);
    localStorage.setItem("language", JSON.stringify(result));
    navigate(0);
  };
  return (
    <FormControl size="small" variant="filled">
      {!!language_id && (
        <Select
          disabled={isLoading}
          id="select-language"
          value={language_id}
          size={"small"}
          displayEmpty
          onChange={handleChange}
          inputProps={{ "aria-label": "Language", style: { color: "#376237" } }}
          sx={{ borderRadius: "30px", color: "#376237", fontWeight: "bold" }}
          startAdornment={
            <LanguageIcon sx={{ display: { xs: "none", sm: "flex" } }} />
          }
          SelectDisplayProps={{
            sx: {
              padding: "10px 32px 10px 10px !important",
              maxWidth: { xs: "80px", sm: "fit-content" },
            },
          }}
          disableUnderline
          IconComponent={(props) => (
            <ExpandMoreIcon {...props} style={{ color: "#376237" }} />
          )}
        >
          {data?.data?.map((e) => {
            return (
              <MenuItem
                key={e.key}
                value={e?.id}
                style={
                  language_id === e?.id
                    ? { background: "#429f46", color: "white" }
                    : {}
                }
              >
                <Typography variant="button" sx={{ textTransform: "initial" }}>
                  {" "}
                  {e.name}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
      )}
    </FormControl>
  );
}

export default memo(SelectLanguage);
