import { getText } from "utils";
import { KEY_LANGUAGE } from "./language";

const _contactUsReplyType = {
  UserSubmitted: {
    id: 1,
    content: (objText) => getText(objText, KEY_LANGUAGE.landing_page_action_1),
  },
  UserReplied: {
    id: 2,
    content: (objText) => getText(objText, KEY_LANGUAGE.landing_page_action_2),
  },
  AdminReplied: {
    id: 3,
    content: (objText) => getText(objText, KEY_LANGUAGE.landing_page_action_3),
  },
  AdminCompleted: {
    id: 4,
    content: (objText) => getText(objText, KEY_LANGUAGE.landing_page_action_4),
  },
};

const _listContactUsReplyType = Object.values(_contactUsReplyType);
const getContentReply = (obj, id) => {
  const result = _listContactUsReplyType.find(e=> e.id === id);
  if(result){
    return result.content(obj)
  }
  return ""
}
const typeModal = {
  notice: {
    key: 'notice',
    title:(objText) => getText(objText, KEY_LANGUAGE.landing_page_notice),
    content: (objText) => getText(objText, KEY_LANGUAGE.landing_page_notice_content),
  },
  success: {
    key: 'success',
    title:(objText) => getText(objText, KEY_LANGUAGE.microsite_contact_us_26),
    content: (objText) => getText(objText, KEY_LANGUAGE.microsite_contact_us_27),
  },
  invalidImage: {
    key: 'invalidImage',
    title:(objText) => getText(objText, KEY_LANGUAGE.landing_page_notice),
    content: (objText) => getText(objText, KEY_LANGUAGE.landing_page_invalid_image),
  }
}

const _listTypeModal = Object.values(typeModal);

const getTypeModal = (obj, key) => {
  const result = _listTypeModal.find(e=> e.key === key);
  if(result){
    return {
      ...result,
      title: result.title(obj),
      content: result.content(obj),
    }
  }
  return {}
}

export {
    _contactUsReplyType,
    _listContactUsReplyType,
    getContentReply,
    typeModal,
    _listTypeModal,
    getTypeModal
}